import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import { urlGetChecklists_x_maquina } from "../../../../api/URLs/mantenimiento";
//HOOKS
import { useModal } from "../../../../hooks/useModal";
import { useNavigate } from "react-router-dom";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
//--Table
import TableMain2 from "../../../../components/Tools/Tables/TableMain2/TableMain2";
//--Basic
import CustomBtn from "../../../../components/Tools/Basics/CustomBtn";
//sql
import { dateSQLformat } from "../../../../functions/dateSQLformat";

const TableMaquinaChkl = ({ maquina }) => {
  const navigate = useNavigate();
  //useModal
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  //States
  const [loading, setLoading] = useState(false);
  const [chkls, setChkls] = useState([]);
  //GET
  const getChkls = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetChecklists_x_maquina, {
        params: {
          id_maquina: maquina.id_maquina,
          id_maquinista: null,
        },
      });
      setChkls(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  const rowClass = (chkl) => {
    return {
      "bg-danger bg-opacity-10": chkl.chk_cerrada === false,
    };
  };
  //HANDLE
  const handleDetails = (chkl) => {
    navigate(`/mantenimiento/checklists/detalle/${chkl.id_resultado_chk}`, {
      state: {
        id_checklist: chkl.id_resultado_chk,
        id_maquina: maquina.id_maquina,
      },
    });
  };
  //BOTONES
  const actions = (chkl) => {
    return (
      <CustomBtn type={"details_table"} onClick={() => handleDetails(chkl)} />
    );
  };
  //COLUMNAS
  const state = (chkl) => {
    return chkl.chk_cerrada ? (
      "Cerrada"
    ) : (
      <span className="text-danger fw-semibold">Abierta</span>
    );
  };
  const horas_uso = (chkl) => {
    return chkl.horas_uso_fin ? chkl.horas_uso_fin : chkl.horas_uso;
  };
  const columns = [
    { header: "Id", field: "id_resultado_chk" },
    { header: "Maquinista", field: "maquinista", sortable: true },
    {
      header: "Fecha",
      field: "fec_restulado_chk",
      formater: "date_time",
      sortable: true,
    },
    {
      header: "Fecha Fin",
      field: "fecha_fin",
      formater: "date_time",
      sortable: true,
    },
    {
      header: "Cierre Automatico",
      field: "fechaCoincide",
      formater: "boolean_color",
    },
    { header: "Hs uso", body: horas_uso },
    { header: "Estado", field: "chk_cerrada", body: state, sortable: true },
    { body: actions },
  ];
  useEffect(() => {
    getChkls();
  }, []);

  const verificarYAgregarCampo = (chkls) => {
    if (chkls.length === 0) return chkls;

    return chkls.map((chkl, index) => {
      // Si el registro no tiene fecha de fin, no se puede comparar
      if (!chkl.fecha_fin) {
        return { ...chkl, fechaCoincide: 0 };
      }

      // Convertir la fecha de fin actual al formato deseado
      const fechaFinActual = dateSQLformat(chkl.fecha_fin, "addTime");

      // Verificar si alguna fecha de inicio coincide con la fecha de cierre actual
      const coincide = chkls.some(
        (otro) =>
          otro.fec_restulado_chk &&
          dateSQLformat(otro.fec_restulado_chk, "addTime") === fechaFinActual
      );

      return { ...chkl, fechaCoincide: coincide ? 1 : 0 };
    });
  };

  // Uso de la función
  const chklsConCampoAdicional = verificarYAgregarCampo(chkls);
  //console.log(chklsConCampoAdicional);
  return (
    <div>
      <TableMain2
        data={chklsConCampoAdicional}
        title={"Listado de Checklists"}
        columns={columns}
        loading={loading}
        textFilter
        rowClass={rowClass}
        sortField={"fec_restulado_chk"}
      />
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default TableMaquinaChkl;
